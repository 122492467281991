import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import ResultState from "./components/ResultState";
import Payment from "./components/Payment";
import {
  CHECK_TRANSACTION_MS,
  INITIAL_DELAY,
  REDIRECT_COUNTDOWN_MS,
  _TRANSACTION_STATUS,
} from "./shared/Constants";
import moment from "moment";

import "../src/App.css";

const App = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [transactionStatus, setTransactionStatus] = useState(
    _TRANSACTION_STATUS.PENDING,
  );
  const [transactionEndTime, setTransactionEndTime] = useState();
  const [params, setParams] = useState();
  const [redirectParams, setRedirectParams] = useState(null);
  const [qrExpiry, setQrExpiry] = useState();
  const [currentTime, setCurrentTime] = useState(moment());

  var checkInterval;

  /**
   * Author: A
   */
  useEffect(() => {
    let params = {
      qr: searchParams.get("qr"),
      merchant: searchParams.get("merchant"),
      amount: searchParams.get("amount"),
      refNo: searchParams.get("refNo"),
      code: searchParams.get("code"),
      signature: searchParams.get("signature"),
      callBackError: searchParams.get("callBackError"),
      redirectUrl: decodeURIComponent(searchParams.get("redirectUrl")),
    };

    if (Object.values(params)) {
      if (params.callBackError) {
        setRedirectParams({
          ...params,
        });
        setLoading(false);
      } else {
        setParams(params);
      }
      checkTransaction(params.refNo, params.signature, params.code);
    } else {
      setLoading(true);
    }
  }, []);

  /**
   * Author: A
   */
  useEffect(() => {
    if (params?.refNo && params?.signature && params?.code) {
      setTimeout(() => {
        checkInterval = setInterval(checkTransaction, CHECK_TRANSACTION_MS);
      }, INITIAL_DELAY);
    }
  }, [params]);

  /**
   * Author: A
   */
  useEffect(() => {
    if (transactionStatus != _TRANSACTION_STATUS.PENDING) {
      clearInterval(checkInterval);
    }
  }, [transactionStatus]);

  /**
   * Author: A
   */
  useEffect(() => {
    if (redirectParams) {
      setTimeout(
        () => {
          document.getElementById("redirectForm").submit();
        },
        redirectParams.callBackError ? 0 : REDIRECT_COUNTDOWN_MS,
      );
    }
  }, [redirectParams]);

  /**
   * Author: A
   */
  const checkTransaction = async (
    id = null,
    sig = null,
    merc = null,
    forceComplete = false,
  ) => {
    let url = `${
      process.env.REACT_APP_BASE_URL
    }transaction/check?transactionId=${id ?? params.refNo}&signature=${
      sig ?? params.signature
    }&code=${merc ?? params.code}&complete=${forceComplete}`;

    await fetch(url, {method: "GET"})
      .then(async (response) => {
        let jsonResult = await response.json();

        if (jsonResult.success) {
          let data = jsonResult.data;

          if (!data) {
            setTransactionStatus(_TRANSACTION_STATUS.INVALID);
            return;
          }

          let qrExpireTime = moment(currentTime).add(2, "minute");
          let endTime = moment(currentTime).add(5, "minute");

          setQrExpiry(qrExpireTime);

          if (data.isCompleted) {
            setTransactionStatus(
              data.isSuccess
                ? _TRANSACTION_STATUS.SUCCESS
                : _TRANSACTION_STATUS.EXPIRED,
            );
            setRedirectParams(data.data);
          } else {
            if (!transactionEndTime) {
              setTransactionEndTime(endTime);
            }
          }
        } else {
          setTransactionStatus(_TRANSACTION_STATUS.INVALID);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {!loading && (
        <div className="text-center">
          {(transactionStatus == _TRANSACTION_STATUS.SUCCESS ||
            transactionStatus == _TRANSACTION_STATUS.INVALID) && (
            <ResultState
              amount={params.amount}
              refNo={params.refNo}
              status={transactionStatus}
            />
          )}
          {params &&
            (transactionStatus == _TRANSACTION_STATUS.PENDING ||
              transactionStatus == _TRANSACTION_STATUS.EXPIRED) && (
              <Payment
                refNo={params.refNo}
                amount={params.amount}
                qr={params.qr}
                merchant={params.merchant}
                transactionEndTime={transactionEndTime}
                submit={(forceComplete) =>
                  checkTransaction(null, null, null, forceComplete)
                }
                status={transactionStatus}
                qrExpiry={qrExpiry}
              />
            )}
          {redirectParams && (
            <form
              id="redirectForm"
              method="POST"
              action={redirectParams.redirectUrl}
            >
              <input hidden name="Status" value={redirectParams.status} />
              <input hidden name="RefNo" value={redirectParams.refNo} />
              <input hidden name="BankRefNo" value={redirectParams.bankRefNo} />
              <input hidden name="Amount" value={redirectParams.amount} />
              <input hidden name="Signature" value={redirectParams.signature} />
              <input
                hidden
                name="TransactionDate"
                value={redirectParams.transactionDate}
              />
              <input
                hidden
                name="CallbackError"
                value={redirectParams.callBackError}
              />
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default App;
