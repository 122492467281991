import React, { useEffect, useState } from 'react';
import DuitNowLogo from '../../src/assets/svg/duitnow.svg';
import QRCode from 'react-qr-code';
import Moment from 'react-moment';
import { _TRANSACTION_STATUS } from '../shared/Constants';
import BankList from './BankList';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Payment = ({ refNo, amount, merchant, qr, transactionEndTime, submit, status, qrExpiry }) => {

    const [showBanks, setShowBanks] = useState(false);

    const options = {
        title: 'Cancel Transaction?',
        message: 'You will be redirected back to your merchant webite.',
        buttons: [
            {
                label: 'Yes',
                onClick: () => submit(true)
            },
            {
                label: 'No',
                onClick: () => { }
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypress: () => { },
        onKeypressEscape: () => { },
    };

    return (
        <>
            <img src={DuitNowLogo} className="mt-3 mb-2 w-[70px] xs:w-[25px] m-auto" alt="DuitNow QR" />
            <div className='mb-3 xs:pb-40'>
                <div className='mt-10 mb-5 xs:mt-5'>
                    <p>{refNo}</p>
                    <p>{merchant}</p>
                    <div className='text-2xl font-bold'>RM {parseFloat(amount).toFixed(2)}</div>
                </div>
                <div className={`bg-qr-code inline-block p-4 mb-5 rounded-xl border-white border-2 ${(status != _TRANSACTION_STATUS.EXPIRED && moment().isBefore(qrExpiry)) ? 'qr-active' : 'qr-expired'}`}>
                    <QRCode value={qr} fgColor="#E62D64" bgColor="transparent" className='mx-auto' />
                </div>
                {
                    (status != _TRANSACTION_STATUS.EXPIRED && moment().isBefore(qrExpiry)) ?
                        <p className='flex justify-center items-center' onClick={() => setShowBanks(true)}>
                            <div className='mr-3 rounded-full bg-green-300 w-5 h-5 block shadow-md shadow-green-300/50'>?</div>
                            <div className='text-left'>
                                Screenshot and pay with <br /><span className='text-blue-700'>participating banks &amp; e-Wallets</span>
                            </div>
                        </p>
                        :
                        <p>Transaction expired. <br />Press 'Done' after payment is completed. You will be redirected to the merchant's website in a moment.</p>
                }
            </div>
            {
                status != _TRANSACTION_STATUS.EXPIRED ?
                    <div className='fixed bottom-0 left-0 shadow-sm w-full p-5 text-center'>
                        <div>
                            <div className='mb-3 rounded-full countdown-wrapper inline-block px-3 py-2'>
                                {moment().isBefore(qrExpiry) ? "QR Expire in" : "Processing your transaction"}
                                <Moment
                                    className='inline-block ml-1 text-red-500 animate-pulse'
                                    date={moment().isBefore(qrExpiry) ? qrExpiry : transactionEndTime}
                                    durationFromNow
                                    filter={(t) => t.indexOf('-') >= 0 ? t.replace('-', '') : '0:00'}
                                    interval={1000}
                                    format="m:ss"
                                />
                            </div>
                        </div>
                        <div className='flex'>
                            <button onClick={() => confirmAlert(options)} className='transition-all w-full p-3 mr-3 rounded-md bg-gray-300 shadow-md shadow-gray-300/50 hover:shadow-none text-gray-800'>
                                Cancel
                            </button>
                            <button onClick={() => submit(moment().isAfter(qrExpiry))} className='transition-all w-full p-3 rounded-md bg-green-300 shadow-md shadow-green-300/50 hover:shadow-none text-gray-800'>
                                Done
                            </button>
                        </div>
                    </div> :
                    <div className='fixed bottom-0 left-0 shadow-sm w-full p-5 text-center'>
                        <div>
                            <div className='mb-3 rounded-full countdown-wrapper inline-block px-3 py-2 text-red-600'>Expired</div>
                        </div>
                    </div>
            }
            <BankList show={showBanks} toggle={() => setShowBanks(false)} />
        </>
    );
};

export default Payment;