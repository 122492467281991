import React from 'react';
import Boost from '../assets/png/ewallet/boost.png';
import GrabPay from '../assets/png/ewallet/grabpay.png';
import ShopeePay from '../assets/png/ewallet/shopeepay.png';
import Tng from '../assets/png/ewallet/tng.png';

import Cimb from '../assets/png/banks/cimb.png';
import CimbIslamic from '../assets/png/banks/cimb-islamic.png';
import Affin from '../assets/png/banks/affin.png';
import AffinIslamic from '../assets/png/banks/affin-islamic.png';
import AgroBank from '../assets/png/banks/agrobank.png';
import Alliance from '../assets/png/banks/alliance.png';
import AllianceIslamic from '../assets/png/banks/alliance-islamic.png';
import BankIslam from '../assets/png/banks/bank-islam.png';
import Muamalat from '../assets/png/banks/muamalat.png';
import BankChina from '../assets/png/banks/bank-of-china.png';
import Citibank from '../assets/png/banks/citibank.png';
import Hongleong from '../assets/png/banks/hongleong.png';
import HongleongIslamic from '../assets/png/banks/hongleong-islamic.png';
import Hsbc from '../assets/png/banks/hsbc.png'
import HsbcAmanah from '../assets/png/banks/hsbc-amanah.png'
import Icbc from '../assets/png/banks/icbc.png'
import Maybank from '../assets/png/banks/maybank.png'
import MaybankIslamic from '../assets/png/banks/maybank-islamic.png'
import Ocbc from '../assets/png/banks/ocbc.png'
import OcbcAlAmin from '../assets/png/banks/ocbc-alamin.png'
import PublicBank from '../assets/png/banks/publicbank.png'
import PublicBankIslamic from '../assets/png/banks/publicbank-islamic.png'
import Rhb from '../assets/png/banks/rhb.png'
import RhbIslamic from '../assets/png/banks/rhb-islamic.png'
import StandardChartered from '../assets/png/banks/standardchartered.png'
import StandardCharteredSaadiq from '../assets/png/banks/standardchartered-saadiq.png'
import Uob from '../assets/png/banks/UOB.png'

const BankList = ({ show, toggle }) => {

    const banks = [
        { image: Cimb },
        { image: CimbIslamic },
        { image: Maybank },
        { image: MaybankIslamic },
        { image: PublicBank },
        { image: PublicBankIslamic },
        { image: Hongleong },
        { image: HongleongIslamic },
        { image: AgroBank },
        { image: Rhb },
        { image: RhbIslamic },
        { image: Affin },
        { image: AffinIslamic },
        { image: Alliance },
        { image: AllianceIslamic },
        { image: BankIslam },
        { image: Muamalat },
        { image: BankChina },
        { image: Citibank },
        { image: Hsbc },
        { image: HsbcAmanah },
        { image: Icbc },
        { image: Ocbc },
        { image: OcbcAlAmin },
        { image: StandardChartered },
        { image: StandardCharteredSaadiq },
        { image: Uob }
    ];

    const eWallets = [
        { image: Tng },
        { image: GrabPay },
        { image: ShopeePay },
        { image: Boost }
    ]

    return (
        <>
            {
                show &&
                <div className='fixed top-0 left-0 w-full h-full bg-slate-900/75'>
                    <div className='w-80 max-h-[80%] bg-white absolute top-1/2 left-1/2 rounded-md shadow-md -translate-y-1/2 -translate-x-1/2'>
                        <div className='font-bold p-4 bg-slate-400/20'>
                            Participating Banks &amp; e-Wallets
                        </div>
                        <div className='grid grid-cols-2 gap-4 m-4 overflow-y-scroll max-h-[300px] pb-4'>
                            {
                                eWallets.map((wallet) => {
                                    return <img className='shadow-lg shadow-slate-400/20 rounded-md m-auto' src={wallet.image} />
                                })
                            }
                            {
                                banks.map((bank) => {
                                    return <img className='shadow-lg shadow-slate-400/20 rounded-md m-auto' src={bank.image} />
                                })
                            }
                        </div>
                        <div className='p-3'>
                            <button onClick={toggle} className='w-full p-3 rounded-md bg-slate-100 shadow-md'>
                                Return
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default BankList;