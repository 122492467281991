import React, { useState, useEffect } from 'react';
import SuccessStateSvg from '../../src/assets/svg/state-success.svg';
import ErorrStateSvg from '../../src/assets/svg/state-error.svg';
import Background from '../../src/assets/svg/background.svg';
import { REDIRECT_COUNTDOWN_MS, _TRANSACTION_STATUS } from '../shared/Constants';
import Moment from 'react-moment';
import moment from 'moment';

/**
 * Author: A
 */
const SuccessState = ({ amount, refNo, status }) => {

    const [timestamp, setTimestamp] = useState(moment());
    const [redirectTimestamp, setRedirectTimestamp] = useState(moment().add(REDIRECT_COUNTDOWN_MS, 'ms'));

    return (
        <div className='bg-no-repeat bg-cover h-screen flex align-middle justify-center' style={{ backgroundImage: `url(${Background})` }}>
            <div className='flex flex-1 mx-8 flex-col align-middle justify-center'>
                <div className='font-bold text-xl mb-7'>{status == _TRANSACTION_STATUS.SUCCESS ? "Payment Success" : "Invalid Transaction"}</div>
                <img className='mx-auto w-4/5 max-w-[300px] mb-7' src={status == _TRANSACTION_STATUS.SUCCESS ? SuccessStateSvg : ErorrStateSvg} />
                {
                    status == _TRANSACTION_STATUS.SUCCESS &&
                    <div className="text-center">
                        <div className='xs:text-[10px] bg-qr-code inline-block p-5 mb-5 rounded-xl border-white border-2 w-full max-w-[500px]'>
                            <table className='w-full tbl-transaction'>
                                <tr>
                                    <td>Transaction ID</td>
                                    <th>{refNo}</th>
                                </tr>
                                <tr>
                                    <td>Amount</td>
                                    <th>RM {parseFloat(amount).toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <td>Date</td>
                                    <th><Moment format="DD-MM-YYYY hh:mm:ss A">{timestamp}</Moment></th>
                                </tr>
                            </table>
                        </div>
                        <p>
                            Redirecting to merchant in
                            <Moment
                                className='inline-block ml-1 mr-1'
                                date={redirectTimestamp}
                                durationFromNow
                                filter={(t) => t < 0 ? t.replace('-', '') : 0}
                                interval={1000}
                                format="s"
                            />
                            seconds
                        </p>
                    </div>
                }
            </div>
        </div>
    );
};

export default SuccessState;